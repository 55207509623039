<template>
  <div>
    <h1 class="text-h4">Управление товарами</h1>
    <v-divider class="my-2"></v-divider>
    <v-card>
      <v-card-title>
        <v-sheet width="100%">
          <v-row dense no-gutters>
            <v-col cols="12" sm="6">
              <v-combobox
                v-model="table.filter.categories"
                dense
                label="Категории"
                persistent-hint
                chips

                clearable
                multiple
                :items="categoriesData"
                :loading="busy"
                @input="onInputFilter"
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :color="item.isActive?null:'orange'"
                    :input-value="selected"
                  >
                    <span class="pr-2">
                      {{ item.text }}
                    </span>
                    <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6" class="pl-md-1">
              <v-combobox
                v-model="table.filter.tags"
                dense
                label="Теги"
                persistent-hint
                chips
                deletable-chips
                clearable
                multiple
                :items="tagsData"
                :loading="busy"
                @input="onInputFilter"
              >
              </v-combobox>
            </v-col>
          </v-row>
          <v-row no-gutters dense>
            <v-col cols="6" md="3">
              <v-select
                v-model="table.filter.isInStock"
                return-object
                :items="isInStockOptions"
                :disabled="busy"
                label="Наличие"
                @change="onInputFilter"
              ></v-select>
            </v-col>
            <v-col cols="6" md="3" class="pl-1">
              <v-select
                v-model="table.filter.isPublic"
                return-object
                :items="isPublicOptions"
                :disabled="busy"
                label="Публикация"
                @change="onInputFilter"
              ></v-select>
            </v-col>
            <v-col class="d-flex justify-end pl-md-1" cols="12" md="6">
              <v-text-field
                v-model="table.filter.search"
                append-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
                clearable
                @input="onInputFilter"
              ></v-text-field>
              <v-btn icon class="ml-2 mt-1" outlined @click="toggleAdvancedSearch">
                <v-icon>{{ showAdvancedSearch ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
        <!--        <v-spacer></v-spacer>-->
        <!--        <v-select-->
        <!--          v-model="table.filter.isPublic"-->
        <!--          return-object-->
        <!--          :items="isPublicOptions"-->
        <!--          :disabled="busy"-->
        <!--          label="Публикация"-->
        <!--          @change="onInputFilter"-->
        <!--        ></v-select>-->
        <!--        <v-text-field-->
        <!--          v-model="table.filter.search"-->
        <!--          append-icon="mdi-magnify"-->
        <!--          label="Поиск"-->
        <!--          single-line-->
        <!--          hide-details-->
        <!--          clearable-->
        <!--          @input="onInputFilter"-->
        <!--        ></v-text-field>-->
        <!--        <v-btn icon class="ml-2 mt-1" @click="toggleAdvancedSearch">-->
        <!--          <v-icon>{{ showAdvancedSearch ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>-->
        <!--        </v-btn>-->
      </v-card-title>
      <transition name="slide">
        <v-card-text v-show="showAdvancedSearch">
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-combobox
                v-model="table.filter.categories"
                dense
                label="Категории"
                hint="Укажите категории для фильтрации"
                persistent-hint
                chips

                clearable
                multiple
                :items="categoriesData"
                :loading="busy"
                @input="onInputFilter"
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :color="item.isActive?null:'orange'"
                    :input-value="selected"
                  >
                    <span class="pr-2">
                      {{ item.text }}
                    </span>
                    <v-icon small @click="parent.selectItem(item)">$delete</v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-combobox
                v-model="table.filter.tags"
                dense
                label="Теги"
                hint="Укажите теги для фильтрации"
                persistent-hint
                chips
                deletable-chips
                clearable
                multiple
                :items="tagsData"
                :loading="busy"
                @input="onInputFilter"
              >
              </v-combobox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-select
                v-model="table.filter.isInStock"
                return-object
                :items="isInStockOptions"
                :disabled="busy"
                label="Наличие"
                @change="onInputFilter"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="table.filter.activity"
                return-object
                :items="activityOptions"
                :disabled="busy"
                label="Статус"
                @change="onInputFilter"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="table.filter.yandexMarketActivity"
                return-object
                :items="yandexMarketActivityOptions"
                :disabled="busy"
                label="Yandex экспорт"
                @change="onInputFilter"
              ></v-select>
            </v-col>
          </v-row>
          <v-switch
            v-model="table.filter.withLowPrice"
            label="Показать только со сниженной ценой"
            :disabled="busy"
            dense
            @change="onInputFilter"
          />
        </v-card-text>
      </transition>
      <v-card-actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              color="warning"
              :disabled="busy"
              v-bind="attrs"
              @click="resetFilter"
              v-on="on"
            >
              <v-icon>mdi-filter-variant-remove</v-icon>
            </v-btn>
          </template>
          <span>Сбросить фильтр</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can:access="{type:'acl', id:'Admin'}"
              fab
              color="success"
              :disabled="busy"
              v-bind="attrs"
              :to="{ name: 'item.create' }"
              v-on="on"
            >
              <v-icon>mdi-file-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Добавить товар</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can:access="{type:'acl', id:'Admin'}"
              fab
              color="error"
              :disabled="!hasSelected"
              class="ml-1"
              v-bind="attrs"
              v-on="on"
              @click="deleteSelectedAction"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>Удалить группу</span>
        </v-tooltip>
        <v-menu offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-can:access="{type:'acl', id:'Admin'}"
                  :disabled="!hasSelected"
                  v-bind="attrs"
                  fab
                  class="ml-1"
                  v-on="{ ...tooltip, ...menu}"
                >
                  <v-icon>mdi-list-status</v-icon>
                </v-btn>

              </template>
              <span>Изменить статус</span>
            </v-tooltip>

          </template>
          <v-list dense>
            <v-subheader>Наличие</v-subheader>
            <v-list-item @click="setInStockSelectedAction(true)">
              <v-list-item-title>В наличии</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setInStockSelectedAction(false)">
              <v-list-item-title>Отсутствует</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-subheader>Статус</v-subheader>
            <v-list-item @click="setIsActiveSelectedAction(true)">
              <v-list-item-title>Активный</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setIsActiveSelectedAction(false)">
              <v-list-item-title>Заблокирован</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-subheader>Yandex Market</v-subheader>
            <v-list-item @click="setYandexMarketActiveSelectedAction(true)">
              <v-list-item-title>Включить</v-list-item-title>
            </v-list-item>
            <v-list-item @click="setYandexMarketActiveSelectedAction(false)">
              <v-list-item-title>Выключить</v-list-item-title>
            </v-list-item>

          </v-list>
        </v-menu>
      </v-card-actions>
      <v-data-table
        v-model="table.selectedItems"
        :loading="busy"
        :headers="table.headers"
        :items="items"
        :server-items-length="itemsTotalCount"
        :footer-props="table.footerProps"
        :options.sync="table.options"
        show-select
        :item-class="tableItemClass"
      >
        <template v-slot:item.test="{ item }">
          <list-item-card :item="item" />
        </template>

        <!--        <template v-slot:item.id="{ item }">-->
        <!--          {{ item.id | shortenId }}-->
        <!--        </template>-->

        <!--        <template v-slot:item.image="{ item }">-->
        <!--          <router-link :to="{ name:'item.view',params: {id:item.id} }">-->
        <!--            <v-img-->
        <!--              :src="item.image | imageUrl('thumb', false)"-->
        <!--              height="70"-->
        <!--              width="70"-->
        <!--              class="rounded my-1"-->
        <!--            ></v-img>-->
        <!--          </router-link>-->
        <!--        </template>-->

        <template v-slot:item.isActive="{ item }">
          <v-icon v-if="item.isActive===false" small color="error">
            mdi-close-circle
          </v-icon>
          <v-icon v-else-if="item.isInStock===true" small color="success">
            mdi-check-circle
          </v-icon>
          <v-icon v-else-if="item.isInStock===false" small color="warning">
            mdi-pause-circle
          </v-icon>
        </template>
        <template v-slot:item.price="{ item }">
          <div v-if="item.priceOld>0" class="d-flex flex-column">
            <span class="orange--text font-weight-bold">{{ item.price }}</span>
            <span class="text-decoration-line-through">{{ item.priceOld }}</span>
          </div>
          <span v-else>{{ item.price }}</span>
        </template>
        <template v-slot:item.name="{ item }">
          <router-link :to="{ name:'item.view',params: {id:item.id} }">{{ item.name }}</router-link>
        </template>
        <template v-slot:item.export="{ item }">
          <div class="d-flex flex-column">
            <v-icon v-if="item.isPublic" small color="green">mdi-web</v-icon>
            <v-icon v-if="item.exportYandexMarket" small>mdi-database-export-outline</v-icon>
            <!--            <v-avatar v-if="item.exportYandexMarket" size="12">-->
            <!--              <v-img :src="require('@/assets/images/ym_96.png')" />-->
            <!--            </v-avatar>-->
          </div>
        </template>
        <template v-slot:item.meta.updatedAt="{ item }">
          {{ item.meta.updatedAt | formatDateTime }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            fab
            text
            small
            :to="{ name: 'item.view', params: { id: item.id} }"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            class="ml-1"
            @click="deleteAction(item)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="deleteDialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title class="title error--text">Удаление данных</v-card-title>
        <v-card-text class="my-2">
          <div class="text-body-2">
            Вы действительно хотите произвести удаление данных?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="deleteItemConfirm">
            Продолжить
          </v-btn>
          <v-btn text @click="deleteDialog.show = false">
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapMutations, mapState } from 'vuex'
import ToastService from '@/service/ToastService'
import { Asc, Desc } from '@/model/SortOrder'
import Page from '@/model/Page'
import _ from 'lodash'
import ItemFilter from '@/model/item/ItemFilter'
import ListItemCard from '@/pages/item/ListItemCard.vue'

export default {
  ...dashboardPageCommonAttributes,
  name: 'ItemListPage',
  components: { ListItemCard },
  data: function () {
    return {
      table: {
        selectedItems: [],
        options: {
          sortBy: ['code'],
          sortDesc: [true],
          itemsPerPage: 30
        },
        headers: [
          // { text: 'ID', align: 'left', value: 'id' },
          { text: '', align: 'left', sortable: false, value: 'test' },
          // { text: 'Код', align: 'left', value: 'code' },
          // { text: '', value: 'image' },
          { text: '', value: 'isActive' },
          // { text: '', value: 'isInStock' },
          { text: 'Цена', value: 'price' },
          // { text: 'Название', value: 'name' },
          { text: 'Инфо', value: 'export', sortable: false },
          { text: 'Изменен', value: 'meta.updatedAt' },
          { text: 'Действия', value: 'actions', sortable: false, align: 'right' }
        ],
        filter: {
          search: '',
          categories: [],
          tags: [],
          activity: null,
          yandexMarketActivity: null,
          withLowPrice: false,
          isInStock: null,
          isPublic: null
        },
        footerProps: {
          itemsPerPageOptions: [10, 30, 50, 70, 100],
          itemsPerPageText: 'Строк на странице:',
          // showCurrentPage:true,
          showFirstLastPage: true
        }
      },
      deleteDialog: {
        show: false,
        value: []
      },
      isInStockOptions: [
        { text: 'Все', value: null },
        { text: 'В наличии', value: true },
        { text: 'Отсутствует', value: false }
      ],
      isPublicOptions: [
        { text: 'Все', value: null },
        { text: 'Опубликовано', value: true },
        { text: 'Нет', value: false }
      ],
      activityOptions: [
        { text: 'Все', value: 'All' },
        { text: 'Активные', value: 'Active' },
        { text: 'Заблокированные', value: 'Blocked' }
      ],
      yandexMarketActivityOptions: [
        { text: 'Все', value: 'All' },
        { text: 'Да', value: 'Active' },
        { text: 'Нет', value: 'Blocked' }
      ]
    }
  },
  computed: {
    isFilterDefault() {
      const filter = {
        search: '',
        categories: [],
        tags: [],
        activity: null,
        yandexMarketActivity: null,
        withLowPrice: false,
        isInStock: null,
        isPublic: null
      }

      return _.isEqual(filter, this.table.filter)
    },
    ...mapState('item_list', ['items', 'busy', 'itemsTotalCount', 'filter', 'page', 'showAdvancedSearch']),
    ...mapState('item_list', {
      'categoriesData': function(state) {
        const activeCategories = state.categories
          .filter((o) => o.isActive)
          .map((o) => { return { text: o.name, value: o.id, isActive: o.isActive } })

        const blockedCategories = state.categories
          .filter((o) => !o.isActive)
          .map((o) => { return { text: o.name, value: o.id, isActive: o.isActive } })

        return [
          { header: 'Активные' },
          ...activeCategories,
          { divider: true },
          { header: 'Заблокированные' },
          ...blockedCategories
        ]
      },
      'tagsData': function(state) {
        return state.tags
          .map((o) => { return { text: o.name, value: o.id } })
      }
    }),
    hasSelected() {
      return this.table.selectedItems.length > 0
    }
  },
  watch: {
    'table.options': {
      handler() {
        this.loadTable()
      },
      deep: true
    },
    'categoriesData': {
      handler(newValue, oldValue) {
        this.table.filter.categories = this.filter.categoryIds.map((e) => {
          return newValue.find((o) => o.value === e)
        })
      }
    },
    'tagsData': {
      handler(newValue, oldValue) {
        this.table.filter.tags = this.filter.tagIds.map((e) => {
          return newValue.find((o) => o.value === e)
        })
      }
    }
  },
  created() {
    this.fetchCategories().then()

    this.table.filter.search = this.filter.search

    const tableOptions = {
      sortBy: [],
      sortDesc: [],
      page: this.page.page + 1,
      itemsPerPage: this.page.size
    }

    this.page.sort.forEach((s) => {
      tableOptions.sortBy.push(s.field)
      tableOptions.sortDesc.push(s.order === 'Desc')
    })
    this.table.options = tableOptions

    const { filter } = this.$store.state.item_list

    this.table.filter.isPublic = this.isPublicOptions.find((v) => v.value === filter.isPublic)
    this.table.filter.isInStock = this.isInStockOptions.find((v) => v.value === filter.isInStock)
    this.table.filter.activity = this.activityOptions.find((v) => v.value === filter.activity)
    this.table.filter.yandexMarketActivity = this.yandexMarketActivityOptions.find((v) => v.value === filter.yandexMarketActivity)

    this.table.filter.withLowPrice = filter.withLowPrice
  },
  methods: {
    ...mapMutations('item_list', {
      setPage: 'SET_PAGE',
      setFilter: 'SET_FILTER',
      toggleAdvancedSearch: 'TOGGLE_ADVANCED_SEARCH'
    }),
    ...mapActions('item_list', ['fetchData', 'fetchCategories', 'deleteItem', 'setIsActive', 'setInStock', 'setYandexMarketActive']),
    loadTable() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.table.options

      const sort = []

      sortBy.forEach((field, index) => {
        sort.push({
          field,
          order: (sortDesc[index]) ? Desc : Asc
        })
      })

      const p = Page.create({
        page: page - 1,
        size: itemsPerPage,
        sort
      })

      this.setPage({ page: p })

      this.fetchData()
    },
    deleteAction(item) {
      this.deleteDialog.value = [item]
      this.deleteDialog.show = true
    },
    deleteSelectedAction() {
      this.deleteDialog.value = this.table.selectedItems
      this.deleteDialog.show = true
    },
    deleteItemConfirm() {
      const ids = this.deleteDialog.value.map((item) => item.id)

      this.deleteItem({ ids })
        .then(() => {
          ToastService.success('Данные сохранены')
          this.deleteDialog.value = []
          this.deleteDialog.show = false
          this.table.selectedItems = []
        })
    },
    setIsActiveSelectedAction(isActive) {
      const ids = this.table.selectedItems
        .filter((item) => item.isActive !== isActive)
        .map((item) => item.id)

      if (ids.length === 0) {
        this.table.selectedItems = []

        return
      }

      this.setIsActive({ ids, isActive })
        .then(() => {
          ToastService.success('Данные сохранены')
          this.table.selectedItems = []
        })
    },
    setInStockSelectedAction(isInStock) {
      const ids = this.table.selectedItems
        .filter((item) => item.isInStock !== isInStock)
        .map((item) => item.id)

      if (ids.length === 0) {
        this.table.selectedItems = []

        return
      }

      this.setInStock({ ids, isInStock })
        .then(() => {
          ToastService.success('Данные сохранены')
          this.table.selectedItems = []
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    },
    setYandexMarketActiveSelectedAction(isActive) {
      const ids = this.table.selectedItems
        .filter((item) => item.exportYandexMarket !== isActive)
        .map((item) => item.id)

      if (ids.length === 0) {
        this.table.selectedItems = []

        return
      }

      this.setYandexMarketActive({ ids, isActive })
        .then(() => {
          ToastService.success('Данные сохранены')
          this.table.selectedItems = []
        })
    },
    onInputFilter: _.debounce(function () {
      const { isPublic, isInStock, search, activity, yandexMarketActivity, withLowPrice } = this.table.filter

      const filter = ItemFilter.create({
        search,
        activity: activity?.value,
        yandexMarketActivity: yandexMarketActivity?.value,
        categoryIds: this.table.filter.categories.map((o) => o.value),
        tagIds: this.table.filter.tags.map((o) => o.value),
        withLowPrice: withLowPrice,
        isInStock: isInStock?.value,
        isPublic: isPublic?.value
      })

      this.setFilter({ filter })

      this.table.options.page = 1

      this.loadTable()
    }, 500),
    tableItemClass(item) {
      if (item.isActive === false) {
        return ['blocked']
      }
      if (item.isInStock === false) {
        return ['out-of-stock']
      }

      return ''
    },
    resetFilter() {
      this.table.filter = {
        search: '',
        categories: [],
        tags: [],
        activity: null,
        yandexMarketActivity: null,
        withLowPrice: false,
        isInStock: null,
        isPublic: null
      }
      this.onInputFilter()
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Товары'
      }
    }
  }
}
</script>

<style>
.theme--light tr.blocked {
  //background-color: lightgoldenrodyellow;
  background-color: #ffccd3;
}
.theme--light tr.blocked:hover {
  background-color: #ffccd3 !important;
  //background-color: lightpink !important;
}

.theme--dark tr.blocked {
  color: lightcoral;
}

.theme--dark tr.blocked:hover {
  color: lightcoral !important;
}

.theme--light tr.out-of-stock, .theme--light tr.out-of-stock:hover {
  background-color: lightgoldenrodyellow !important;
}

.theme--dark tr.out-of-stock, .theme--dark tr.out-of-stock:hover {
  color: khaki !important;
}

</style>
